._button{
  &:hover{
    @media(min-width: $laptop){
      background-color: $themeColor;
      @include transition(.5s, .1s);
    }
  }  
}

._nav-widescreen{
  > ul{
    > li{
      > a:not(._button){
        position: relative;
        padding: 15px 0;
        &:after{
          content: "";
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          width: 0px;
          height: 1px;
          background-color: $themeColor;
          visibility: hidden;
          opacity: 0;
          @include transition(.25s);
        }
        &:hover, &.active{
          &:after{
            visibility: visible;
            opacity: 1;
            width: 30px;
          }
        }
      }
    }
  }
}

._loader-container{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: $dark;
  z-index: 1000;
  visibility: visible;
  opacity: 1;
  @include transition(.5s, .5s);
  &._hide{
      visibility: hidden;
      opacity: 0;
  }
  ._loader-centered-box-container{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      ._loader {
          width: 8px;
          height: 40px;
          border-radius: 4px;
          display: block;
          margin: 20px auto;
          position: relative;
          background: currentColor;
          color: $themeColor;
          box-sizing: border-box;
          animation: animloader 0.3s 0.3s linear infinite alternate;
          &:after, &:before{
              content: '';
              width: 8px;
              height: 40px;
              border-radius: 4px;
              background: currentColor;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 20px;
              box-sizing: border-box;
              animation: animloader 0.3s  0.45s  linear infinite alternate;
          }
          &:before{
              left: -20px;
              animation-delay: 0s;
          }
      }
      
      @keyframes animloader {
          0%   { height: 48px} 
          100% { height: 4px}
      }
  }
}


._product-overview-column{
  overflow: hidden;
  ._link-item{
    overflow: hidden;
    img{
      @include transition;
    }
    &:hover{
      ._scale{
        overflow: hidden;
        img{
          transform: scale(1.1);
        }
      }
    }
  }
}