._header{
  position: fixed;
  z-index: 2;
  width: 100%;
  top: 0;
  left: 0;
  height: 80px;
  background-color: transparent;
  @include transition(.5s, .5s);
  &._transformed{
    background-color: $black;
  }
  > ._grid{
    height: 100%;
    > ._column{
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  ._nav-widescreen{
    height: 100%;
    > ul{
      height: 100%;
      list-style: none;
      padding-left: 0;
      display: flex;
      margin-top: 0;
      > li{
        height: 100%;
        margin-left: 60px;
        display: flex;
        align-items: center;
        > a{
          margin: 30px 0;
          white-space: nowrap;
        }
      }
    }
  }
  @media(min-width: $laptop){
    height: 100px;
  }
}

._logo{
  display: inline-block;
  width: 190px;
}

._nav-mobile{
  background-color: $dark;
  visibility: hidden;
  opacity: 0;
  @include transition(.5s, .1s);
  position: fixed;
  left: 0;
  top: 80px;
  height: calc(100vh - 80px);
  width: 100%;
  z-index: 2;
  &._open{
    visibility: visible;
    opacity: 1;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  > ul{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    text-align: center;
    > li{
      > a:not(._button){
        display: inline-block;
        padding-top: 14px;
        padding-bottom: 14px;
        font-size: 20px;
      }
    }
  }
  @media(min-width: $laptop){
    top: 100px;
    height: calc(100vh - 100px);
    display: none;
  }
}

._footer{
  background-color: $black;
  @include topBottomPadding;
  text-align: center;
  ._social-icons{
    justify-content: center;
  }
  @media(min-width: $tablet){
    text-align: left;
    ._social-icons{
      justify-content: flex-end;
    }
    ._inner-grid{
      display: flex;
      justify-content: space-between;
    }
  }
}

._footer-nav{
  font-size: 14px;
  > ul{
    list-style: none;
    padding-left: 0;
    margin-top: 0;
    > li{
      margin: 20px 0;
    }
  }
  font-weight: 100;
  &._bold{
    font-weight: 600;
  }
}