/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * halcom:
 *   - http://typekit.com/eulas/00000000000000007735b039
 *   - http://typekit.com/eulas/00000000000000007735b04a
 *   - http://typekit.com/eulas/00000000000000007735b05a
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-02-17 11:36:50 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=zku2xsn&ht=tk&f=32156.32160.32164&a=11692842&app=typekit&e=css");

@font-face {
font-family:"halcom";
src:url("https://use.typekit.net/af/f74592/00000000000000007735b04a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/f74592/00000000000000007735b04a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/f74592/00000000000000007735b04a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
font-display:swap;
font-style:normal;
font-weight:300;
font-stretch:normal;
}

@font-face {
font-family:"halcom";
src:url("https://use.typekit.net/af/a8369e/00000000000000007735b05a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/a8369e/00000000000000007735b05a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/a8369e/00000000000000007735b05a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:swap;
font-style:bold;
font-weight:700;
font-stretch:normal;
}

@import "variables";
@import "mixins";
@import "helpers";
@import "global";
@import "grid";
@import "ui";
@import "form";
@import "layout";
@import "pages";
@import "animations";
@import "dashboard";