._mobile{
  display: block;
  @media(min-width: $laptop){
      display: none;
  }
}

._widescreen{
  display: none;
  @media(min-width: $laptop){
      display: block;
  }
}

._clearfix{
  clear: both;
}

._theme-color{
  color: $themeColor;
}

._dark{
  color: $dark;
}

._light{
  color: $light;
}

._flex{
  display: flex;
}

._pos-center{
  @include positionCenter;
}

._pos-center-x{
  @include positionCenterX;
}

._pos-center-y{
  @include positionCenterY;
}

._orange{
  color: $themeColor;
}

._grey{
  color: $grey;
}

._bold{
  font-weight: 600;
}

._inst-type{
  font-weight: 100;
  font-size: 0.9rem;
  color: $grey;
}