._section-were-here-to-help{
  @include topBottomSpacing;
  text-align: center;
  ._heading-1{
    margin: 0 0 10px 0
  }
  p{
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

._hero-banner{
  background-position: center;
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  position: relative;
  ._overlay{
    background-color: $darkOpague;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  ._grid{
    margin-top: 30px;
    position: relative;
    z-index: 1;
  }
  @media(min-width: $tablet){
    height: 500px;
    ._overlay{
      top: 50%;
      transform: translateY(-40%);
      height: 300px;
      width: 350px;
    }
  }
  @media(min-width: $laptop){
    height: 100vh;
    ._overlay{
      width: 430px;
      height: 350px;
    }
  }
  @media(min-width: 1285px){
    ._overlay{
      width: calc(50% - 150px);
    }
  }
}

._products-categories{
  ._heading{
    display: flex;
    align-items: center;
    white-space: nowrap;
    font-weight: 100;
    &:after{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $grey;
      opacity: 0.2;
      margin-left: 40px;
    }
    &:before{
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $grey;
      opacity: 0.2;
      margin-right: 40px;
    }
  }
}

._product-gallery-slider{
  position: relative;
  img{
    height: 350px;
    width: auto;
    margin: 0 auto;
  }
  @media(min-width: $laptop){
    img{
      height: 450px;
      width: auto;
      max-width: 100%;
      margin: 0 15px;
    }
  }
  @media(min-width: $tablet){
    .slick-slide{
      @include transition;
      &:not(.slick-active){
        opacity: .15;
      }
    }
  }
}

._info-banner{
  background-color: $black;
  padding: 30px 0;
  > ._grid{
    > ._column{
      > ._item{
        margin: 30px 0;
      }
    }
  }
  @media(min-width: $tablet){
    ._column{
      display: flex;
      > ._item{
        width: 100%;
      }
    }
  }
  @media(min-width: $laptop){
    padding: 50px 0;
  }
}

._installation-type{
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  @media(min-width: $tablet){
    display: block;
    text-align: left;
    span{
      display: block;
    }
  }
}

._fuel-types{
  display: flex;
  justify-content: center;
  ._item{
    margin-left: 30px;
    text-align: center;
    font-size: .6rem;
    font-weight: 100;
    &:nth-of-type(1){
      margin-left: 0;
    }
    img{
      width: auto;
      height: 35px;
    }
  }
}

._docs{
  text-align: center;
  p{
    color: $grey;
    margin: 0;
  }
  ._download-link{
    white-space: nowrap;
    display: block;
    color: #fff;
    margin-top: 4px;
  }
  @media(min-width: $tablet){
    text-align: left;
  }
}

._section-locations{
  text-align: left;
  padding-bottom: 0;
  label{
      font-size: 1rem;
  }
  ._message{
    margin: 0;
    padding-bottom: 100px;
    text-align: center;
  }
  p{
      margin-top: 0;
      margin-bottom: 15px;
  }
  ._panel{
      display: flex;
      flex-wrap: wrap;
      ._panel-item{
          width: 100%;
          @media(min-width: 550px){
            width: 50%;
          }
      }
      h3{
          width: 100%;
          
      }
  }
}

._selectProvince{
  width: 100%;
  max-width: 350px;
  margin-bottom: 100px;
  min-height: 60px;
  padding: 19px 20px;
  font-size: 1rem;
  background-color: transparent;
  color: #fff;
  border: 1px solid $grey;
  font-family: $font;
  option{
      background-color: #fff;
      color: $dark;
      font-family: $font;
  }
}

._product-enquiry-section{
  ._heading{
    text-align: center;
    margin-top: 0;
  }
  p{
    text-align: center;
    margin-bottom: 60px;
  }
}

._legal-section{
  p{
    a{
      font-weight: bold;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}