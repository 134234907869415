$dashboardLight: #F0F0F0;
$dashboardDark: #181818;
$dashboardThemeColor: #EE9B38;
$dashboardThemeColorDark: #c7822e;
$dashboardBorder: #dfdbdb;

@mixin dashboardBoxShadow{
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.1);
}

._dashboard-layout{
    background-color: $dashboardLight;
}

// admin
._dashboard-header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $dashboardLight;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    @include dashboardBoxShadow;
    ._breadcrums p{
        font-weight: 400;
    }
    ._menu-icon._close {
        ._hamburger-icon{
            div{
                span{       
                    @include transition;
                    &:nth-of-type(1){
                        top:50%;
                        transform:translateY(-50%) rotate(45deg);
                    }
                    &:nth-of-type(2){
                        top:50%;
                        transform:translateY(-50%) rotate(-45deg);
                    }
                }
            }         
        }
    }
    @media(min-width: $tablet){
        height: 70px;
    }
    @media(min-width: $laptop){
        height: 80px;
    }
    ._item{
        height: 100%;
    }
    a{
        display: inline-block;
    }
    ._dashboard-list{
        height: 100%;
        display: flex;
        margin: 0;
        align-items: center;
        padding-left: 0;
        p{
            margin-top: 0;
        }
        li{
            list-style: none;
            &._breadcrums{
                display: none;
                p{
                    margin: 0;
                }
                a{
                    font-weight: 500;
                    color: $light;
                    &:hover{
                        color: #fff;
                    }
                }
                @media(min-width: $laptop){
                    display: flex;
                }
            }
            
        }
        > li{
            &:nth-of-type(1){
                padding: 10px 15px 10px 20px;
            }
            &:last-child{
                padding: 10px 30px 10px 15px;
            }
            
            @media (min-width:$tablet){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px 10px 15px;
                }
            }
            @media (min-width:$laptop){
                &:nth-of-type(1){
                    padding: 10px 15px 10px 30px;
                }
                &:last-child{
                    padding: 10px 30px;
                    width: calc(100% - 210px);
                }
            }
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width:$laptop){
                &._menu-icon{
                    display: none;
                }
            }
            > ul {
                position: absolute;
                top: 100%;
                right: 20px;
                a{
                    display: block;
                    padding: 7px 0;
                    color: #fff;
                }
                background-color: $dashboardThemeColor;
                color: $dashboardDark;
                padding: 10px 20px;
                @media(min-width: $laptop){
                    right: 0;
                }
                visibility: hidden;
                opacity: 0;
                @include dashboardBoxShadow;
                @include transition;
                border-radius: 5px;
                min-width: 150px;
                > li{
                    padding: 0;
                    
                }
                &._show{
                    visibility: visible;
                    opacity: 1;
                }
                &:before{
                    content: "";
                    width: 15px;
                    height: 15px;
                    background-color: $dashboardThemeColor;
                    position: absolute;
                    top: -7px;
                    right: 14px;
                    transform: rotate(45deg);
                    border-left: 1px solid $dashboardThemeColor;
                    border-top: 1px solid $dashboardThemeColor;
                }
            }
        }
    }
    ._logo-li{
        text-align: center;
        background-color: $dashboardDark;
        @media (min-width: $laptop) {
            width: 210px;
            text-align: center;
            a{
                margin: 0 auto;
            }
            svg{
                fill: #fff;
                width: 60px;
                margin: 0 auto;
            }
        }
    }
    ._logo{
        // width: 43px;
        display: block;
        // @media (min-width: $tablet){
        //     width: 55px;
        // }
        // @media (min-width: $laptop){
        //     width: 55px;
        // }
    }
}

._dashboard-side-menu{
    position: fixed;
    top: 60px;
    left: -210px;
    width: 210px;
    bottom: 0;
    @include transition;
    z-index: 1;
    @media(min-width: $tablet){
        top: 70px;
        bottom: 0;
    }
    @media(min-width: $laptop){
        top: 80px;
        bottom: 0;
        left: 0;
    }
    ._overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        bottom: 0;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        @include transition;
        cursor: pointer;
        @media(min-width: $laptop){
            display: none;
        }
    }
    &._show{
        left: 0;
        ._overlay{
            visibility: visible;
            opacity: 1;
        }
    }
    ._list{
        margin: 0;
        height: 100%;
        background-color: $dashboardThemeColor;
        overflow-y: auto;
        padding-left: 0;
        padding-top: 60px;
        font-size: 14px;
        ul{
            background-color: $dashboardThemeColorDark;
            li{
                a{
                    color: $dashboardLight;
                    font-weight: 400;
                }
            }
        }
        li{
            list-style: none;
            position: relative;
            color: $dashboardLight;
            a{
                display: block;
                padding: 12px 30px;
                color: $dashboardLight;
                text-transform: uppercase;
                @include transition(.1s);
                &:hover{
                    color: #fff;
                }
            }
            >ul{
                display: none;
                padding: 12px 0;
                a{
                    padding: 2px 30px;
                }
                &._show{
                    display: block;
                }
            }
        }
        ._arrow{
            &:after{
                content: "";
                position: absolute;
                top: 20px;
                right: 30px;
                display: block;
                width: 7px;
                height: 7px;
                border-right: 1px solid $dashboardLight;
                border-bottom: 1px solid $dashboardLight;
                transform: rotate(45deg);
                @include transition;
            }
            &._up{
                color: $light;
                &:after{
                    transform: rotate(-135deg);
                }
            }
        }
    }
}

._dashboard-content{
    padding-top: 80px;
    width: 100%;
    @include transition;
    padding-bottom: 100px;
    min-height: 100vh;
    color: $dark;
    * {
        color: $dark;
    }
    &._slide{
        margin-left: 210px;
    }
    @media(min-width: $tablet){
        padding-top: 90px;
    }
    @media(min-width: $laptop){
        padding-top: 100px;
        padding-left: 210px;
        min-height: 100vh;
        &._slide{
            margin-left: 0;
        }
    }
    ._heading{
        font-size: 30px;
    }
    ._button{
        font-family: $font;
        background-color: transparent;
        color: $dark;
        min-height: 47px;
        display: inline-block;
        border: 1px solid $themeColor;
        border-radius: 0;
        font-weight: 300;
        padding: 13px 20px;
        font-size: 0.875rem;
        text-align: center;
        // min-width: 168px;
      }
    ._data-table{
        *{
            font-weight: 600;
        }
    }
    ._form-element{
        border: 1px solid #dadadb;
        min-height: 50px;
        padding-left: 10px;
    }
    select._form-element{
        padding: 14px 10px;
    }
    
    ._checkbox-2{
        width: 100%;
        ._item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }
        
        label {
            display: flex;
            margin: auto;
            width: 40px;
            height: 40px;
            cursor: pointer;
        }
        
        input {
            position: absolute;
            transform: scale(0);
            color: $dark;
        }
        
        input:checked ~ ._checkbox {
            transform: rotate(45deg);
            width: 14px;
            height: 28px;
            margin: 0 7px 0 13px;
            border-color: $themeColor;
            border-width: 3px;
            border-top-color: transparent;
            border-left-color: transparent;
            border-radius: 0;
            background-color: transparent;
        }
        
        ._checkbox {
            display: block;
            width: inherit;
            height: inherit;
            border: 1px solid $light;
            background-color: #fff;
            border-radius: 0px;
            transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
        }  
    }
}

._section-header{
    ._breadcrumbs{
        font-size: 16px;
        color: $dark;
    }
    > h1{
        padding-bottom: 60px;
        padding-top: 20px;
        margin: 0;
    }
}

._form-dashboard{
    max-width: 650px;
    margin: 0 auto;
    ._form-row{
        &:nth-of-type(1){
            margin-top: 0;
        }
    }
    label{
        font-weight: 600;
        color: $grey;
    }
}

._success-message{
    color: green;
}

._error-message{
    color: red;
}

._success-group{
    padding: 15px;
    border: 1px solid green;
    border-radius: 5px;
    margin-top: 15px
}

._error-group{
    padding: 15px;
    border: 1px solid red;
    border-radius: 5px;
    margin-top: 15px
}

._buttons-group{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
}

._table-button {
    padding: 7px;
    background-color: $light;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    min-width: 55px;
    text-align: center;
    @include transition;
    white-space: nowrap;
    &._table-button-edit{
        color: green;
    }
    &._table-button-delete{
        color: red;
    }
    &:hover{
        background-color: $light;
    }
}

._button-group{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;
    margin-bottom: 30px;
}

._dashboard-primary-navigation{
    ul{
        padding-left: 0;
        list-style: none;
        display: flex;
        justify-content: center;
        column-gap: 30px;
        flex-wrap: wrap;
        row-gap: 30px;
        li{
            width: 100%;
            max-width: 300px;
        }
    }
    @media(min-width:$tablet){
        ul{
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}

._hr-dashboard{
    background-color: $dashboardBorder;
    height: 1px;
    // display: none;
}

._button-submit{
    @include transition(0.5s);
    position: relative;
    top: 0;
    left: 0;
    &._sending{
        left: 100vw;
    }
}

._button-2-large{
    color: $dark;
    padding: 30px 40px;
    font-size: 24px;
    display: block;
    text-align: center;
    border: 1px solid #dadadb;
    @include transition;
    background-color: #fff;
    font-weight: 600;
    // &:hover{
    //     color: $dark;
    //     // border: 2px solid $grey;
    // }
}

._checkbox-row {
	display: flex;
	align-items: center;
}

._dashboard-content ._form-element{
    background-color: #fff;
}

._menu-button{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
}

._dashboard-table{
    width: 100%;
    border-collapse: collapse;
    
    td{
        padding: 10px;
        vertical-align: baseline;
        background-color: $light;
        border-top: 1px solid $light;
        &:nth-of-type(1){
            font-weight: 600;
        }
    }
}

._dashboard-layout{
    ._form-row ._form-group ._form-element{
        color: $dark;
    }
}

._dashboard-notice{
    // background-color: #fff;
    border-top: 1px solid $light;
    border-bottom: 1px solid $light;
    border-radius: 3px;
    padding: 10px 0;
    margin: 15px 0;
    p, strong, i{
        color: $grey;
        margin: 0;
    }
}

._checkbox-group{
    display: flex;
    width: 100%;
    margin: 30px 0;
}

._checkbox-2{
    ._item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    
    label {
        display: flex;
        margin: auto;
        width: 40px;
        height: 40px;
        cursor: pointer;
    }
    
    input {
        position: absolute;
        transform: scale(0);
    }
    
    input:checked ~ ._checkbox {
        transform: rotate(45deg);
        width: 14px;
        height: 28px;
        margin: 0 7px 0 13px;
        border-color: $themeColor;
        border-width: 3px;
        border-top-color: transparent;
        border-left-color: transparent;
        border-radius: 0;
        background-color: transparent;
    }
    
    ._checkbox {
        display: block;
        width: inherit;
        height: inherit;
        border: 1px solid $grey;
        background-color: $dark;
        border-radius: 0px;
        transition: all 0.2s cubic-bezier(0, 0.01, 0.23, 0.8);
    }  
}