$font: "halcom", sans-serif;

$tablet: 768px;
$laptop: 1124px;
$desktop: 1300px;

$themeColor: #FA5F1A;
$dark: #252525;
$light: #F7F0F0;
$grey: #868686;
$black: #1D1D1D;
$darkOpague: rgba(37, 37, 37, 0.8);