body{
  font-family: $font;
  color: $light;
  background-color: $dark;
  font-size: 16px;
}

._section{
  @include topBottomSpacing;
}

p{
  font-size: 1rem;
  line-height: 1.625rem;
  font-weight: 100;
  small{
    font-size: 0.875rem;
  }
}

._heading-1{
  font-weight: 100;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #fff;
  @media(min-width: $tablet){
    font-size: 2rem;
    line-height: 2.7rem;
  }
  @media(min-width: $laptop){
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
}

._heading-2{
  font-size: 1.2rem;
  line-height: 1.438rem;
  font-weight: 600;
  color: #fff;
}

._button{
  font-family: $font;
  background-color: transparent;
  color: #fff;
  min-height: 47px;
  display: inline-block;
  border: 1px solid $themeColor;
  border-radius: 100px;
  font-weight: 300;
  padding: 14px 20px;
  font-size: 0.875rem;
  text-align: center;
  // min-width: 168px;
}

._hamburger-icon{
  width: 40px;
  height: 40px;
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  > div {
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;
      span{
          position: absolute;
          height: 1px;
          width: 100%;
          background-color: $themeColor;
          left: 50%;
          transform: translateX(-50%);
          @include transition(0.25s);
          &:nth-of-type(1){
              top: 13px;
          }
          &:nth-of-type(2){
              bottom: 13px;
          }
      }
  }
  &._close{
      span{
          @include transition(0.25s);
          &:nth-of-type(1){
              top: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
          }
          &:nth-of-type(2){
              bottom: unset;
              top: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
          }
      }
  }
}

._horline{
  width: 100%;
  height: 1px;
  background-color: $dark;
}

._social-icons{
  display: flex;
  align-items: center;
  padding: 15px 0 0;
  column-gap: 15px;
  list-style: none;
  padding-left:0;
  margin-top: 0;
}

._copywrite{
  display: flex;
  align-items: center;
  width: 100%;
  p{
    margin: 0;
    white-space: nowrap;
    small{
      font-size: 10px;
    }
  }
  ._horline{
    margin-left: 40px;
  }
}

._zigzag-layout{
  section{
    height: 100vh;
    background-position: center;
    background-size: cover;
    ._grid{
      position: relative;
      height: 100%;
      ._content{
        background-color: $darkOpague;
        text-align: center;
        padding: 60px 30px;
        width: 100%;
        max-width: 600px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h1, h2 {
          margin-top: 0;
        }
      }
      @media(min-width: $tablet){
        ._column{
          ._content{
            text-align: left;
            width: 50%;
          }
          &:nth-of-type(1){
            ._content{
              left: -30px;
              transform: translateY(-50%);
            }
          }
          &:nth-of-type(2){
            ._content{
              left: unset;
              right: -30px;
              transform: translateY(-50%);
            }
          }
        }
      }
      @media(min-width: $tablet){
        ._column{
          ._content{
            padding: 60px 40px;
          }
          &:nth-of-type(1){
            ._content{
              left: -40px;
            }
          }
          &:nth-of-type(2){
            ._content{
              right: -40px;
            }
          }
        }
      }
    }
  }
}

._banner{
  text-align: center;
  height: 400px;
  position: relative;
  background-position: center;
  background-size: cover;
  ._grid{
    height: 100%;
    ._column{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      display: relative;
      z-index: 1;
    }
  }
  ._overlay{
    background-color: $darkOpague;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
  ._heading{
    margin-bottom: 50px;
  }
}

.section-category-navbar{
  background-color: $black;
  padding: 0 0 0 0;
  nav{
    width: 100%;
    display: none;
    &._open{
      display: block;
    }
    ul{
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;
      border-top: 1px solid $themeColor;
      padding: 40px 0;
      li{
        a{
          white-space: nowrap;
          display: block;
          text-align: center;
          padding: 10px 0;
          font-weight: 400;
        }
      }
      
    }
    @media(min-width: $laptop){
      display: block;
      ul{
        display: flex;
        justify-content: center;
        border: none;
        li{
          width: auto;
          padding: 0 20px;
          border-right: 1px solid $themeColor;
          &:last-child{
            border: none;
          }
          a{
            padding: 0;
          }
        }
      }
    }
  }
  ._trigger{
    padding: 40px 0;
    font-weight: 600;
    margin: 0 auto;
    display: block;
    text-align: center;
    cursor: pointer;
    span{
      position: relative;
      &:after{
        @include transition;
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        border-right: 1px solid $themeColor;
        border-bottom: 1px solid $themeColor;
        transform: rotate(45deg);
        position: absolute;
        right: -20px;
        top: 3px;
      }
    }
    &._open{
      span{
        &:after{
          transform: rotate(225deg);
          top: 7px;
        }
      }
    }
    @media(min-width: $laptop){
      display: none;
    }
  }
}

._slick-arrow{
  position: absolute;
  top: 50%;
  z-index: 1000;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #Fff;
  cursor: pointer;
  padding-right: 2px;
  padding-top: 2px;
  &:after{
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-right: 1px solid #fff;
    border-top: 1px solid #fff;
  }
  &._next{
    right: 20px;
    transform: rotate(45deg);
  }
  &._prev{
    left: 20px;
    transform: rotate(-135deg);
  }
  @media(min-width: $tablet){
    &._next{
      right: 30px;
    }
    &._prev{
      left: 30px;
    }
  }
  @media(min-width: $laptop){
    &._next{
      right: 100px;
    }
    &._prev{
      left: 100px;
    }
  }
}

.w_dropzone{
  width: 100%;
  height: 200px;
  text-align: center;
  border: 2px solid $grey;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

._map{
  width: 100%;
  padding-bottom: 65%;
  height: 0;
  position: relative;
  iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}